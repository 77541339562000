import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIosNew,
} from "react-icons/md";
import { ResultsType } from "../App";
import { isInvalidCode } from "../functions";
import { useState, useEffect, FC } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

interface ModalInterface {
  image: string;
  index: number | null;
  results: ResultsType[];
  setShowModal: (value: boolean) => void;
  addCode: (i: number, value: string | null) => void;
  handleChangePic: (i: number, direction: string) => void;
}

export const Modal: FC<ModalInterface> = ({
  index,
  image,
  results,
  addCode,
  setShowModal,
  handleChangePic,
}) => {
  const [inputValue, setInputValue] = useState<string>(results[index!]?.code || "");
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const handleAddCode = () => {
    const inputElement = document.getElementById(
      `codeInput-${index}`
    ) as HTMLInputElement;

/*     if (isInvalidCode(inputElement.value)) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);

      addCode(index!, inputValue); 
      if (hasNextUnFoundImage(index!)) {
        handleChangePic(index!, "next");
      } else if (hasPreviousUnFoundImage(index!)) {
        handleChangePic(index!, "prev");
      } else {
        setShowModal(false);
      }
    } */

    addCode(index!, inputValue); 
    if (hasNextUnFoundImage(index!)) {
      handleChangePic(index!, "next");
    } else if (hasPreviousUnFoundImage(index!)) {
      handleChangePic(index!, "prev");
    } else {
      setShowModal(false);
    }
  };

  const hasPreviousUnFoundImage = (index: number): boolean => {
    let i = index - 1;
    while (i >= 0) {
      if (!results[i].isFound) return true;
      i--;
    }
    return false;
  };

  const hasNextUnFoundImage = (index: number): boolean => {
    let i = index + 1;
    while (i < results.length) {
      if (!results[i].isFound) return true;
      i++;
    }
    return false;
  };

  useEffect(() => {
    setInputValue(results[index!]?.code || "");
    setIsInvalid(false);
  }, [index, results]);

  return (
    <div className="fixed w-[100vw] h-[100vh] bg-black bg-opacity-50 top-0 left-0 z-[1]">
      <div className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 text-slate-700 dark:text-slate-200 rounded-sm">
        <div className="relative h-[95vh] w-[1000px] flex">
          {index !== null && hasPreviousUnFoundImage(index) ? (
            <button onClick={() => handleChangePic(index, "prev")}>
              <MdOutlineArrowBackIosNew size={100} />
            </button>
          ) : (
            <div className="w-[100px]"></div>
          )}
          <button
            onClick={() => setShowModal(false)}
            className="absolute right-[80px] -top-5 bg-white rounded-full -p-2"
          >
            <AiFillCloseCircle color="red" size={45} />
          </button>
          <div className="flex flex-col justify-center gap-3 bg-gray-100 pb-1 rounded-md">
            <img src={image} className="h-[92%] w-[800px]" alt="file" />
            {index !== null && (
              <div className="flex gap-1 items-start mx-auto">
                <div>
                  <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    id={`codeInput-${index}`}
                    placeholder="Enter code"
                    className="text-black"
                  />
                  {isInvalid ? (
                    <p className="text-red-500">Value entered is invalid</p>
                  ) : (
                    <p className="h-[24px]"></p>
                  )}
                </div>
                <button
                  className="bg-blue-500 text-white px-3 py-1.5 rounded-sm font-semibold"
                  onClick={handleAddCode}
                >
                  Add
                </button>
              </div>
            )}
          </div>
          {index !== null && hasNextUnFoundImage(index) ? (
            <button onClick={() => handleChangePic(index, "next")}>
              <MdOutlineArrowForwardIos size={100} />
            </button>
          ) : (
            <div className="w-[100px]"></div>
          )}
        </div>
      </div>
    </div>
  );
};
