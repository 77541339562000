import "./App.css";
import {
  getPage,
  base64ToFile,
  getCodeParts,
  isInvalidCode,
} from "./functions";
import axios from "axios";
import Swal from "sweetalert2";
import { FaEye } from "react-icons/fa6";
import ReactLoading from "react-loading";
import { MdDelete } from "react-icons/md";
import { Modal } from "./components/Modal";
import { useState, useRef, DragEvent } from "react";
import * as PDFJS from "pdfjs-dist/legacy/build/pdf";
import { GiConfirmed, GiCancel } from "react-icons/gi";
import withReactContent from "sweetalert2-react-content";
import TopLoadingBar, { LoadingBarRef } from "react-top-loading-bar";

// readers
import jsQR from "jsqr";
import { Decoder } from "@nuintun/qrcode";
import { Html5Qrcode } from "html5-qrcode";

PDFJS.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${PDFJS.version}/build/pdf.worker.min.js`;

export interface ResultsType {
  name: string;
  code?: string;
  base64: string;
  status?: string;
  isFound: boolean;
  completed?: boolean;
  isInvalidCode: boolean;
  messageStatus?: string;
  methodUsed?: string;
}

function App() {
  const MySwal = withReactContent(Swal);

  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<ResultsType[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEye, setShowEye] = useState<number | null>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [picIndex, setPicIndex] = useState<number | null>(null);
  const [modalImage, setModalImage] = useState<string | null>(null);

  const ref = useRef<LoadingBarRef>(null);

  const startLoading = () => {
    ref.current?.continuousStart();
  };

  const finishLoading = () => {
    ref.current?.complete();
  };

  const decodeFile = async () => {
    startLoading();
    const entryFileInput = document.getElementById(
      "entryFile"
    ) as HTMLInputElement;

    if (entryFileInput!.value === "") {
      alert("Please choose a file to scan.");
      return false;
    }
    let methodUsed;

    setIsDisabled(true);
    if (entryFileInput && entryFileInput.value && entryFileInput.files) {
      let fileToAdd: ResultsType[] = [];

      for (let i = 0; i < entryFileInput.files.length; i++) {
        const _file = entryFileInput.files[i];

        if (_file && _file.type) {
          let isFound = false;
          let imageArray: any = [];

          if (_file.type === "application/pdf") {
            const pdfUrl = URL.createObjectURL(_file);
            try {
              const pdf = await PDFJS.getDocument(pdfUrl).promise;
              const pages = [];
              for (let i = 1; i <= pdf.numPages; i++) {
                pages.push(getPage(pdf, i));
              }
              const imageDataArray = await Promise.all(pages);
              imageArray = imageDataArray;
            } catch (error: any) {
              alert(error.message);
            }
          } else if (_file.type.startsWith("image/")) {
            const reader = new FileReader();
            const readFilePromise = new Promise<void>((resolve, reject) => {
              reader.onload = function (event) {
                const base64Image = event!.target!.result;
                imageArray.push(base64Image);
                resolve();
              };
              reader.onerror = function (error) {
                reject(error);
              };
              reader.readAsDataURL(_file);
            });
            await readFilePromise;
          }

          const html5QrCode = new Html5Qrcode("reader");
          const qrCodeResults = [];
          const files = [];

          for (const image of imageArray) {
            const file = base64ToFile(image, "1");
            files.push(file);
          }

          for (const file of files) {
            try {
              const results = await html5QrCode.scanFile(file, true);
              qrCodeResults.push(results);
              console.log(results, "code Found with html5QrCode");
              methodUsed = "html5QrCode";
            } catch (error) {
              console.log("Not found with html5QrCode");
              try {
                const blobUrl = URL.createObjectURL(file);
                const decoder = new Decoder();
                const result = await decoder.scan(blobUrl);
                URL.revokeObjectURL(blobUrl);
                qrCodeResults.push(result.data);
                console.log(result.data, " code Found with Decoder");
                methodUsed = "Decoder";
              } catch (error2) {
                console.log("Not found with Decoder");
                try {
                  const imageData: any = await getImageDataFromFile(file);
                  const code = jsQR(
                    imageData.data,
                    imageData.width,
                    imageData.height
                  );
                  if (code) {
                    console.log(code.data, " code Found with jsQR");
                    methodUsed = "jsQR";

                    qrCodeResults.push(code.data);
                  } else {
                    throw new Error("QR Code not found");
                  }
                } catch (error3) {
                  console.log("Not found with jsQR");
                  qrCodeResults.push("");
                  /*   
                  try {
                    const formData = new FormData();
                    formData.append("image", file);
                    const response = await axios.post(
                      "https://decoder.tradepeg-apps.com/decode-image",
                      formData,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    );
                    methodUsed = "decode-image-API";
                    console.log(response);
                  } catch (error) {
                    console.log(error);
                    qrCodeResults.push("");
                  } */
                }
              }
            }
          }
          if (_file.type === "application/pdf") {
            const pdfUrl = URL.createObjectURL(_file);
            const pdf = await PDFJS.getDocument(pdfUrl).promise;

            for (let pageIndex = 0; pageIndex < pdf.numPages; pageIndex++) {
              var found =
                qrCodeResults.length > pageIndex &&
                qrCodeResults[pageIndex].length > 0;

              const code = found ? qrCodeResults[pageIndex] : "";

              const pageData = {
                name: `${_file.name} - Page ${pageIndex + 1}`,
                base64: imageArray[pageIndex],
                isFound: found,
                code: code,
                isInvalidCode: false,
                methodUsed,
              };

              fileToAdd.push(pageData);
            }
          } else {
            const fileData = {
              name: _file.name,
              base64: imageArray[0],
              isFound: isFound,
              code: qrCodeResults[0],
              isInvalidCode: false,
            };
            fileToAdd.push(fileData);
          }
        }
      }

      let tempDivResult: ResultsType[] = [...results, ...fileToAdd];
      setResults(tempDivResult);
      setIsDisabled(false);

      entryFileInput.value = "";
      finishLoading();
    } else {
      alert("Please provide a valid file");
      finishLoading();
    }
  };

  const getImageDataFromFile = async (file: Blob | MediaSource) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const context = canvas.getContext("2d");
        context?.drawImage(img, 0, 0);
        resolve(context?.getImageData(0, 0, img.width, img.height));
      };
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });
  };

  const alert = (message: any, icon: any = "error") => {
    MySwal.fire({
      icon: icon,
      position: "top",
      title: message,
      showConfirmButton: false,
    });
  };

  const uploadFile = async () => {
    if (!results.length) {
      alert("Please choose a file to upload.");
      return;
    }

    const updatedResults = results.filter((pages) => !pages.completed);
    if (updatedResults.length <= 0) {
      alert("All pages have been uploaded");
      return;
    }

    for (let i = 0; i < results.length; i++) {
      if (!results[i].isFound) {
        alert(`Enter code for ${results[i].name}`);
        return;
      }
    }
    setIsDisabled(true);
    setLoading(true);
    await Promise.all(
      updatedResults.map(async (result, index) => {
        const formData = new FormData();

        // Convert the base64 data to a Blob
        const byteCharacters = atob(result.base64.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/png" });

        formData.append("file", blob, result.code + ".png");
        formData.append("description", "Document Scan");
        formData.append("documentId", result.code!);

        try {
          const currentUrl = window.location.href;
          const url = new URL(currentUrl);
          const token = url.searchParams.get("token");

          const response = await axios.post(
            `https://api-prod.tradepeg.com/documents/attachments/scan-to-cloud`,
            formData,
            {
              headers: {
                Authorization: token,
                "User-Agent": "TradePeg-ScanToCloud/1.0.0",
                "method-used": result.methodUsed || "unknown",
              },
            }
          );

          updatedResults[index] = {
            ...result,
            status: "success",
            messageStatus: "Uploaded successfully",
            completed: true,
          };
        } catch (error: any) {
          const message = error.response?.data?.error || error.message;

          updatedResults[index] = {
            ...result,
            status: "failed",
            messageStatus: `Error: ${message}`,
            completed: false,
          };
        }
      })
    );
    setResults(updatedResults);
    setLoading(false);
    setIsDisabled(false);
  };

  const removeFile = (index: number) => {
    const updatedResults = [...results];
    updatedResults.splice(index, 1);
    setResults(updatedResults);
  };

  const addCode = (index: number, inputValue: string | null) => {
    if (!inputValue) {
      const inputElement = document.getElementById(
        `codeInput-${index}`
      ) as HTMLInputElement;

      inputValue = inputElement.value;
    }

    const updatedResults = [...results];

    /*     if (isInvalidCode(inputValue)) {
      updatedResults[index].isInvalidCode = true;
    } else {
      updatedResults[index] = {
        ...updatedResults[index],
        isInvalidCode: false,
        code: inputValue,
        isFound: true,
      }; */

    updatedResults[index] = {
      ...updatedResults[index],
      isInvalidCode: false,
      code: inputValue,
      isFound: true,
    };

    setResults(updatedResults);
  };

  const handleFileDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const inputElement = document.getElementById(
      "entryFile"
    ) as HTMLInputElement;
    const files = e.dataTransfer.files;

    const acceptedFormats = [
      "image/jpeg",
      "image/gif",
      "image/png",
      "application/pdf",
    ];

    if (!files.length) {
      return;
    }

    const validFiles = Array.from(files).filter((file) =>
      acceptedFormats.includes(file.type)
    );

    if (validFiles.length === 0) {
      alert(
        "Dropped files are not in the accepted formats.(Allowed files: jpeg, png, pdf)"
      );
      return;
    }

    if (!inputElement) return;
    inputElement.files = files;

    await decodeFile();
  };

  const handleChangePic = (i: number, direction: string) => {
    let newIndex = i;

    if (direction === "prev") {
      newIndex--;

      while (newIndex >= 0 && results[newIndex].isFound) {
        newIndex--;
      }

      if (newIndex >= 0) {
        setPicIndex(newIndex);
        setModalImage(results[newIndex].base64);
      }
    } else {
      newIndex++;

      while (newIndex < results.length && results[newIndex].isFound) {
        newIndex++;
      }

      if (newIndex < results.length) {
        setPicIndex(newIndex);
        setModalImage(results[newIndex].base64);
      }
    }
  };

  return (
    <div className="-m-1">
      <TopLoadingBar color="#03bf5f" ref={ref} height={3} />
      {showModal && modalImage && (
        <Modal
          image={modalImage}
          index={picIndex}
          setShowModal={setShowModal}
          handleChangePic={handleChangePic}
          results={results}
          addCode={addCode}
        />
      )}
      <div
        className="formHolder"
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
        }}
        onDrop={handleFileDrop}
      >
        <div>
          <div className="homepage">
            <h1 className="text-3xl font-bold text-center">
              TradePeg Scan To Cloud
            </h1>
            <label
              htmlFor="dropContainer"
              className="drop-container"
              id="dropContainer"
            >
              <span className="drop-title">Drop file here</span>
              or
              <input
                id="entryFile"
                type="file"
                multiple
                accept="image/jpeg,image/gif,image/png,application/pdf"
                onChange={decodeFile}
              />
            </label>
            {results.length > 0 ? (
              <div>
                <div className="relative w-[340px] h-6 bg-gray-300 rounded-full mb-3">
                  <div
                    className="absolute left-0 top-0 h-6 bg-green-400 rounded-full"
                    style={{
                      width: `${
                        (results.filter((result) => result.isFound).length /
                          results.length) *
                        100
                      }%`,
                    }}
                  ></div>
                  <div className="absolute inset-0 flex justify-center items-center">
                    <span>
                      {results.filter((result) => result.isFound).length}
                      <span className=""> out of </span>
                      {results.length} found
                    </span>
                  </div>
                </div>
                <div className="buttons">
                  <button
                    onClick={uploadFile}
                    disabled={
                      isDisabled ||
                      results.filter((result) => result.isFound).length !==
                        results.length
                    }
                    id="upload_btn"
                    className="button upload-button bg-blue-500 hover:bg-blue-700 text-white"
                  >
                    Upload files
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex flex-wrap m-5 mt-10">
            {results.length > 0
              ? results.map((result, i: number) => (
                  <div
                    key={i}
                    className="relative flex flex-col gap-2 items-center justify-center w-full md:w-1/2 xl:w-1/3 2xl:w-1/4 mb-14"
                  >
                    {!loading && result.status === "failed" && (
                      <div className="absolute top-[170px] mx-10 flex flex-col gap-5 items-center z-[2]">
                        <GiCancel size={90} color="red" />
                        <p className="text-center text-red-500 text-xl font-semibold bg-white bg-opacity-70">
                          {result.messageStatus}
                        </p>
                      </div>
                    )}
                    {!loading && result.status === "success" && (
                      <div className="absolute top-[170px] mx-10 flex flex-col gap-5 items-center z-[2]">
                        <GiConfirmed size={90} color="green" />
                        <p className="text-center text-green-600 text-xl font-semibold bg-white bg-opacity-70">
                          {result.messageStatus}
                        </p>
                      </div>
                    )}
                    {loading && (
                      <div className="absolute top-[170px]">
                        <ReactLoading
                          type={"spinningBubbles"}
                          color={"#b8b8b8"}
                          height={150}
                          width={150}
                        />
                      </div>
                    )}
                    <div
                      className={
                        "flex rounded-t-lg" +
                        (result.isFound ? " bg-green-200" : " bg-red-200")
                      }
                    >
                      <span className="w-[50px] flex justify-center items-center cursor-pointer"></span>
                      <p className="text-xl w-[250px] text-center p-2">
                        {result.name.length > 20
                          ? "..." + result.name.slice(-20)
                          : result.name}
                      </p>
                      <button
                        onClick={() => removeFile(i)}
                        className="w-[50px] text-red-500 flex justify-end p-2"
                      >
                        <MdDelete size={25} />
                      </button>
                    </div>
                    <div
                      className="relative"
                      onMouseEnter={() => setShowEye(i)}
                      onMouseLeave={() => setShowEye(null)}
                    >
                      {showEye === i && !result.isFound && (
                        <button
                          onClick={() => {
                            setPicIndex(i);
                            setShowModal(true);
                            setModalImage(result.base64);
                          }}
                          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-green-600 rounded-full p-2"
                        >
                          <FaEye size={40} color="white" />
                        </button>
                      )}
                      <img
                        className="w-[350px] h-[500px] border-[1.5px]"
                        src={result.base64}
                        alt="file"
                      />
                    </div>
                    {result.isFound ? (
                      <p className="text-lg h-[60px]">
                        Found:{" "}
                        <span className="font-semibold">{result.code}</span>
                      </p>
                    ) : (
                      <div className="flex gap-1 items-start">
                        <div>
                          <input
                            type="text"
                            defaultValue={result.code}
                            id={`codeInput-${i}`}
                            placeholder="Enter code"
                          />
                          {result.isInvalidCode ? (
                            <p className="text-red-500">
                              Value entered is invalid
                            </p>
                          ) : (
                            <p className="h-[24px]"></p>
                          )}
                        </div>
                        <button
                          className="bg-blue-500 text-white px-3 py-1.5 rounded-sm font-semibold"
                          onClick={() => addCode(i, null)}
                        >
                          Add
                        </button>
                      </div>
                    )}
                  </div>
                ))
              : null}
          </div>
          <div id="holder"></div>
        </div>
        <div className="hidden" id="reader"></div>
      </div>
    </div>
  );
}

export default App;
